//
// Google font - Poppins
//

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

//
// Premium Font : HKGrotesk
//

@font-face {
    font-family: "hkgrotesk";
    src: url("../../fonts/hkgrotesk-light.eot");
    src: local('hkgrotesk light'), url("../../fonts/hkgrotesk-light.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "hkgrotesk";
    src: url("../../fonts/hkgrotesk-regular.eot");
    src: local('hkgrotesk regular'), url("../../fonts/hkgrotesk-regular.woff") format("woff");
    font-weight: 400;
}
@font-face {
    font-family: "hkgrotesk";
    src: url("../../fonts/hkgrotesk-medium.eot");
    src: local('hkgrotesk medium'), url("../../fonts/hkgrotesk-medium.woff") format("woff");
    font-weight: 500;
}
@font-face {
    font-family: "hkgrotesk";
    src: url("../../fonts/hkgrotesk-semibold.eot");
    src: local('hkgrotesk semibold'), url("../../fonts/hkgrotesk-semibold.woff") format("woff");
    font-weight: 600;
}
@font-face {
    font-family: "hkgrotesk";
    src: url("../../fonts/hkgrotesk-bold.woff");
    src: local('hkgrotesk bold'), url("../../fonts/hkgrotesk-bold.woff") format("woff");
    font-weight: 700;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Regular.woff2');
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
    url('../../fonts/Gilroy-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-ExtraboldItalic.woff2');
    src: local('Gilroy Extrabold Italic'), local('Gilroy-ExtraboldItalic'),
    url('../../fonts/Gilroy-ExtraboldItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Bold.woff2');
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
    url('../../fonts/Gilroy-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Black.woff2');
    src: local('Gilroy Black'), local('Gilroy-Black'),
    url('../../fonts/Gilroy-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Light.woff2');
    src: local('Gilroy Light'), local('Gilroy-Light'),
    url('../../fonts/Gilroy-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Semibold.woff2');
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
    url('../../fonts/Gilroy-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Medium.woff2');
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
    url('../../fonts/Gilroy-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-MediumItalic.woff2');
    src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
    url('../../fonts/Gilroy-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-BlackItalic.woff2');
    src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
    url('../../fonts/Gilroy-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-UltraLight.woff2');
    src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
    url('../../fonts/Gilroy-UltraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-RegularItalic.woff2');
    src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
    url('../../fonts/Gilroy-RegularItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-SemiboldItalic.woff2');
    src: local('Gilroy Semibold Italic'), local('Gilroy-SemiboldItalic'),
    url('../../fonts/Gilroy-SemiboldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-HeavyItalic.woff2');
    src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
    url('../../fonts/Gilroy-HeavyItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Extrabold.woff2');
    src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
    url('../../fonts/Gilroy-Extrabold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-BoldItalic.woff2');
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
    url('../../fonts/Gilroy-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-UltraLightItalic.woff2');
    src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
    url('../../fonts/Gilroy-UltraLightItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-LightItalic.woff2');
    src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
    url('../../fonts/Gilroy-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Heavy.woff2');
    src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
    url('../../fonts/Gilroy-Heavy.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Thin.woff2');
    src: local('Gilroy Thin'), local('Gilroy-Thin'),
    url('../../fonts/Gilroy-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-ThinItalic.woff2');
    src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
    url('../../fonts/Gilroy-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
}


