//
// _nft-landing.scss
//

.bookmark-icon {
    .btn {
        color: var(--#{$prefix}secondary-color);
        background-color: var(--#{$prefix}light) !important;
        box-shadow: $box-shadow;
        font-size: $font-size-base;
        &:hover,
        &.active {
            border-color: transparent;
            color: $danger;
        }
    }
}

.explore-box {
    border-radius: 9px;
    overflow: hidden;
    .explore-img {
        height: 280px;
        object-fit: cover;
    }
    .explore-place-bid-img {
        position: relative;
        overflow: hidden;
        z-index: 0;
        .bg-overlay {
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: rgba($dark, 0.4);
            opacity: 0;
            transition: all 0.5s ease;
        }
    
        .place-bid-btn {
            top: 50%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
    
            .btn {
                opacity: 0;
                bottom: -25px;
                transition: 0.5s ease;
            }
        }
    }
    
    &:hover {
        .explore-place-bid-img {
            .place-bid-btn {
                .btn {
                    opacity: 1;
                    bottom: 0;
                }
            }
    
            .bg-overlay {
                opacity: 1;
            }
        }
    }
    .discount-time {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        background-color: rgba($white, 0.4);
        backdrop-filter: blur(5px);
        padding: 8px;
    }
}

//
.nft-hero {
    background-size: cover;
    background-position: bottom;
    padding: 222px 0 150px 0;
    .bg-overlay {
        background-color: #05175f;
        opacity: 0.85;
    }
}

#explorecard-list {
    .list-element {
        display: none;

        &:nth-child(-n+10) {
            display: block;
        }
    }
}
