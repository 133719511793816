@import "variables.scss";

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.form-label {
  &.descr {
    color: #878A99;
  }

  &:after {
    content: " *";
    color: #FF3B30;
  }
}

.toast {
  &.bg-danger {
    background-color: #FDE8E4 !important;
    border: 1px solid #FBD1C8 !important;
    color: #B44C36 !important;
    opacity: 1 !important;
  }

  &.bg-success {
    background-color: #E4FDE5 !important;
    border: 1px solid #C8FBD8 !important;
    color: #3AB436 !important;
    opacity: 1 !important;
  }

  &.bg-secondary {
    background-color: #322F35 !important;
    border: 1px solid #322F35 !important;
    color: #F5EFF7 !important;
    opacity: 1 !important;
  }
}

.btn {
  &.loading {
    color: transparent !important;
    position: relative;
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border: 2px solid #fff;
      border-right: 2px solid transparent;
      border-radius: 50%;
      animation: circle 1s linear infinite;
      left: calc(50% - 10px);
    }

    &.star {
      &:before {
        border-right-color: #FF8E25;
        width: 12px;
        height: 12px;
        left: 1px;
      }

      i {
        color: transparent !important;
      }
    }

    svg {
      opacity: 0;
    }

    img {
      opacity: 0;
    }
  }
}

@keyframes circle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.badge {
  font-size: 11px;

  &.bg-danger {
    background-color: #F06548 !important;
    color: #ffffff !important;
  }
}

.btn-auth {
  background-color: $btn !important;
  border: unset !important;
  color: #FFFFFF !important;

  &:hover {
    background-color: tint-color($btn, 20%) !important;;
  }

  &.transparent {
    border: 1px solid #45758C !important;
    background-color: transparent !important;
    color: #45758C !important;

    &:hover {
      background-color: tint-color(#333, 90%) !important;;
    }
  }
}

.btn-settings {
  background-color: #FF8E25 !important;
  border: unset !important;
  color: #FFFFFF !important;

  &:hover {
    background-color: tint-color(#FF8E25, 20%) !important;;
  }

  &.transparent {
    border: unset !important;
    background-color: transparent !important;
    color: #FF8E25 !important;

    &.red {
      color: #F06548 !important;
    }

    &:hover {
      background-color: tint-color(#333, 90%) !important;;
    }
  }
}

.ng-select.select-container {
  height: 38px;

  &.project-select {
    .ng-select-container {
      cursor: pointer !important;
      background-color: #4D4D4D !important;

      .ng-placeholder {
        color: #fff
      }

      .ng-value {
        color: #fff
      }

      .ng-arrow {
        border-color: #fff transparent transparent !important;
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        .ng-value-container {
          .ng-placeholder {
            display: none;
          }
        }

        .ng-arrow {
          border-color: transparent transparent #fff !important;
        }

        .ng-input {
          input {
            color: #fff !important;
          }
        }
      }
    }
  }

  .ng-select-container {
    cursor: pointer !important;
    height: 38px;

    .ng-input {
      top: 7px !important;

      input {
        cursor: pointer !important;
      }
    }
  }

  .ng-clear-wrapper {
    color: #fff !important;

    .ng-clear {
      padding-top: 5px;
    }
  }

  .ng-dropdown-panel {
    background-color: #fff !important;

    .ng-option-name {
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal !important;
    }
  }
}

.ng-select.history-types-list {
  .ng-dropdown-panel {
    background-color: #fff !important;
  }

  .ng-select-container {
    height: 38px;

    .ng-value-container {
      padding-top: 6px;

      .ng-placeholder {
        padding: 3px 0 0 4px;
      }

      .ng-value {
        background-color: #FF8E25;

        .ng-value-icon {
          transition: .2s;

          &:hover {
            background-color: #FF8E25;
          }
        }
      }
    }

    .ng-clear-wrapper {
      padding-top: 3px;
    }
  }
}

.ng-select.default-list {
  &.is-invalid {
    .ng-select-container {
      border-color: #fa896b !important;
    }
  }

  .ng-dropdown-panel {
    background-color: #fff !important;
  }

  &.ng-select-multiple {
    .ng-select-container {
      min-height: 38px;

      .ng-value-container {
        padding: 4px 8px;
        gap: 4px;

        .ng-placeholder {
          padding: 3px 0 0 4px;
        }

        .ng-value {
          background-color: #4D4D4D;
          display: flex;
          margin: 0;
          height: 26px;
          align-items: center;
          border-radius: 2px;

          .ng-value-icon {
            order: 2;
            transition: .2s;
            border: none !important;
            font-size: 18px;
            padding: 0 8px;
            transform: unset !important;
            background-color: transparent !important;
            height: 26px;

            &:hover {
              background-color: #4D4D4D;
            }
          }

          .ng-value-label {
            order: 1;
          }
        }
      }

      .ng-clear-wrapper {
        padding-top: 3px;
      }
    }
  }
}

ul.ngx-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;

  li {
    width: auto;
    height: 30px;
    border: 2px solid transparent;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border: 2px solid #333;
    }
  }

  li.current {
    display: flex;
    justify-content: center;
    align-items: center;
    background: green;
    padding-left: 12px;
    padding-right: 12px;

    &:hover {
      border-color: transparent;
    }
  }

  li a {
    padding-left: 12px;
    padding-right: 12px;

    &:hover {
      background: transparent;
    }
  }

  li.ellipsis {
    border: none;
  }

  li a span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #000000;
  }
}

.settings-table {
  th,
  td {
    border: unset;
    padding: 6px 12px;
  }

  th {
    font-size: 12px;
    font-weight: 400;
    color: #4D4D4D;
    background-color: transparent;
  }

  tr.selected {
    background-color: #FFF4DF !important;
    border-top: 6px solid #fff;
    border-bottom: 6px solid #fff;
    border-radius: 4px;

    td {
      padding: 2px 12px;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: #F7F7F7;
      }

      &:nth-child(even) {
        background-color: transparent;
      }
    }

    td {
      font-size: 14px;
      font-weight: 400;
      color: #4D4D4D;
      vertical-align: middle;
    }
  }
}

.media-slider {
  .owl-stage {
    display: flex;
    align-items: center;
  }

  .owl-theme {
    position: relative;

    .owl-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      position: relative;
      width: 100%;
      z-index: 1;

      .owl-prev, .owl-next {
        border-radius: 100%;
        width: 32px;
        height: 32px;
        z-index: 2;
        transition: .2s;

        &:hover {
          opacity: .7;
        }
      }

      .owl-prev {
        left: 0;
        background: #FF8E25 url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTYuNjcgMGwyLjgzIDIuODI5LTkuMzM5IDkuMTc1IDkuMzM5IDkuMTY3LTIuODMgMi44MjktMTIuMTctMTEuOTk2eiIgZmlsbD0iI0ZGRkZGRiIvPjwvc3ZnPg==") no-repeat 9px 10px;
        background-size: auto 13px;
      }

      .owl-next {
        right: 0;
        background: #FF8E25 url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNy4zMyAyNGwtMi44My0yLjgyOSA5LjMzOS05LjE3NS05LjMzOS05LjE2NyAyLjgzLTIuODI5IDEyLjE3IDExLjk5NnoiIGZpbGw9IiNGRkZGRkYiLz48L3N2Zz4=") no-repeat 10px 10px;
        background-size: auto 13px;
      }
    }

    .owl-dots {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 40px;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .owl-dot {
        width: 22px;
        height: 5px;
        background: #FF8E25 url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDIzIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjAuNSIgeT0iMC43ODYyNTUiIHdpZHRoPSIyMiIgaGVpZ2h0PSI1IiByeD0iMi41IiBmaWxsPSIjRkFCQzQ2IiBmaWxsLW9wYWNpdHk9IjAuMiIvPgo8L3N2Zz4K") no-repeat;
        background-size: 100%;
        border-radius: 5px;
        opacity: .25;

        &.active {
          opacity: 1;
        }
      }
    }
  }
}

.nav-tabs-progress {
  gap: 18px;

  .nav-item {
    .nav-link {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      color: #000000;
      padding: 10px 12px;

      &.active {
        background-color: #FF8E25;
        color: #ffffff;
        border-radius: 4px;

        .badge {
          background-color: #FFFFFF;
          color: #FF8E25;
        }
      }

      .badge {
        background-color: #9599AD;
        margin-left: 6px;
      }
    }
  }
}

.search-result-accordion {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .accordion-item {
    border: none;
    border-radius: 8px;
    overflow: hidden;

    .accordion-header {
      background-color: #F6F7F8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 18px;

      span {
        font-size: 14px;
        font-weight: 700;
        color: #45758C;
      }

      button {
        width: 20px;
        height: 20px;
        box-shadow: none;
        background-color: #fff;
        border-radius: 6px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .accordion-collapse {
      background-color: #F6F7F8;

      .accordion-body {
        padding-top: 0;
      }
    }
  }
}

.suggestions-list-accordion {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .accordion-item {
    border: none;
    border-radius: 8px;
    overflow: hidden;

    .accordion-header {
      background-color: #F6F7F8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      &:not(.collapsed) {
        background-color: transparent;
        border-radius: 8px 8px 0 0;
        border: 1px solid #D1D7DC !important;
        border-bottom: none !important;

        &.is-location {
          border-color: #FF8E25 !important;
          background-color: rgba(255, 142, 37, .02);
        }
      }

      button {
        padding: 22px 18px;
        background-color: transparent;
        color: #45758C;
        font-size: 14px;
        font-weight: 700;
        box-shadow: none;
        display: flex;
        gap: 12px;
        align-items: center;

        .badge {
          color: #FFFFFF !important;
          padding: 4px 10px;

          &.in-progress {
            background-color: #45758C !important;

          }
        }

        .avatar {
          img {
            width: 32px;
            height: 32px;
            object-fit: cover;
          }
        }
      }
    }

    .accordion-collapse {
      background-color: transparent;
      border-radius: 0 0 8px 8px;
      border: 1px solid #D1D7DC !important;
      border-top: none !important;

      &.is-location {
        border-color: #FF8E25 !important;
        background-color: rgba(255, 142, 37, .02);
      }

      .accordion-body {
        padding-top: 0;
      }
    }
  }
}

.result-dropzone {
  &.report {
    .dz-message {
      height: 400px;
    }
  }

  &.task {
    .dz-message {
      height: 248px;
      margin: 0 !important;
    }
  }

  &.import-entities {
    .dz-message {
      height: 440px;
      margin: 0 !important;
    }
  }

  .dz-message {
    display: flex !important;
    flex-direction: column;
    gap: 8px;
    border: 2px dashed #CFD1DA !important;
    border-radius: 10px !important;
    justify-content: center;

    .dz-text {
      position: relative !important;
      top: unset !important;
      width: 100% !important;
      padding: 8px 16px !important;
      text-align: center !important;
      transform: unset !important;
      left: unset !important;
      right: unset !important;
      opacity: 1 !important;
      order: 2;
      font-size: 18px !important;
      font-weight: 400 !important;
      color: #495057 !important;
    }

    .dz-image {
      height: 60px !important;
      filter: unset !important;
      order: 1;
    }
  }

  .dz-preview {
    background-color: transparent !important;;

    .dz-progress {
      display: none;
    }

    .dz-remove {
      background-color: transparent;
    }
  }
}

.comment-dropzone {
  display: none;
}

.comment-dropzone-preview {
  .dz-message {
    display: none !important;
  }

  .dz-preview {
    background-color: transparent !important;;

    .dz-progress {
      display: none;
    }

    .dz-remove {
      background-color: transparent;
    }
  }
}

.after-none:after {
  display: none;
}

.btn-default {
  background-color: #FF8E25 !important;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 11px;
  color: #fff !important;
  transition: .2s;
  border-radius: 4px;
  font-weight: 500;

  &:hover {
    opacity: 0.8;
  }
}

.btn-gray {
  background-color: #F7F7F7 !important;
  border: 1px solid #4D4D4D;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 11px;
  color: #4D4D4D !important;
  transition: .2s;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    border: 1px solid #4D4D4D;
  }
}

.btn-transparent {
  background-color: transparent !important;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 11px;
  color: #4D4D4D !important;
  transition: .2s;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.default {
    color: #FF8E25 !important;
    font-weight: 600;
  }
}

.btn-danger {
  background-color: #F06548 !important;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 11px;
  color: #fff !important;
  transition: .2s;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
  }
}

.avatar-placeholder {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  background-color: #FF8E25;
  color: #fff;
  text-align: center;

  &.text {
    width: 140px;
    height: 140px;
    font-size: 20px;
    padding: 16px;
  }
}

.dropdown-menu {
  a {
    color: #4D4D4D;

    &.selected {
      background-color: #F0F0F0;
      pointer-events: none;
      cursor: not-allowed !important;
    }
  }
}

.breadcrumb-list {
  .breadcrumb-item {
    color: #495057;
    font-weight: 500;

    &.active {
      color: #878A99;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 300px;
      overflow: hidden;
    }
  }
}

.avatar-group {
  flex-wrap: nowrap;

  .avatar-group-item {
    width: 32px;
    height: 32px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    font-size: 12px;
  }
}

ngb-tooltip-window.show {
  opacity: 1 !important;
}

.lg-container {
  position: relative;
  z-index: 1100;

  .lg-outer[data-lg-slide-type='video'] .lg-zoom-in, .lg-outer[data-lg-slide-type='video'] .lg-zoom-out {
    display: none;
  }

  .lg-show-in {
    .lg-prev, .lg-next {
      background-color: #FF8E25;
      border-radius: 100%;
      background-size: auto 13px;
      width: 32px;
      height: 32px;

      &:before {
        color: #fff;
      }
    }

    .lg-prev {
      &:after {
        content: "";
      }

      background: #FF8E25 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTYuNjcgMGwyLjgzIDIuODI5LTkuMzM5IDkuMTc1IDkuMzM5IDkuMTY3LTIuODMgMi44MjktMTIuMTctMTEuOTk2eiIgZmlsbD0iI0ZGRkZGRiIvPjwvc3ZnPg==) no-repeat 9px 10px;
      background-size: auto 13px;
    }

    .lg-next {
      &:before {
        content: "";
      }

      background: #FF8E25 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNy4zMyAyNGwtMi44My0yLjgyOSA5LjMzOS05LjE3NS05LjMzOS05LjE2NyAyLjgzLTIuODI5IDEyLjE3IDExLjk5NnoiIGZpbGw9IiNGRkZGRkYiLz48L3N2Zz4=) no-repeat 10px 10px;
      background-size: auto 13px;
    }
  }
}

.comment-list {
  .comment {
    .content {
      .message {
        p {
          margin: 0;
        }

        .clickable-code {
          cursor: pointer;
          user-select: none;
          color: #EC7E18;
          font-size: 14px;
          white-space: pre-wrap;
        }
      }
    }
  }
}

.task-card .text .title p {
  margin: 0;
}

.notifications-container {
  .notification-item {
    .text-muted {
      p {
        margin: 0;
      }
    }
  }
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option {
      &.ng-option-selected {
        background-color: #FFE7D1 !important;
        color: #FF8E25 !important;
        border-radius: 0 !important;
      }
    }
  }
}

.project-container, .system-settings-container {
  .settings-tab {
    .tab-pane {
      width: 100%;
    }
  }
}

.form-check.default {
  height: 18px;
  display: flex;
  align-items: center;

  input {
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-color: #4D4D4D !important;
    margin: 0 !important;


    &:checked {
      border-color: #FF8E25 !important;
    }

    &.head-checkbox {
      &:checked {
        background-color: #FF8E25;
      }
    }

    &:before {
      top: unset !important;
      left: 3px !important;
      color: #FF8E25;
    }
  }
}

.drag-status-item, .drag-category-item {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;

  input {
    flex: 1;
  }
}

.drag-category-item {
  input {
    width: 360px;
  }
}

.field-item.drag {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;

  .name {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }

  .form-check {
    justify-content: center;
  }
}

tr.cdk-drag-preview {
  td {
    opacity: 0;

    &:first-child {
      opacity: 1;
    }
  }
}


.task-tags {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  gap: 6px;

  .tag {
    background-color: #32ADE6;
    border-radius: 4px;
    padding: 4px 8px;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    white-space: nowrap;
    text-align: center;
  }

  button {
    width: 24px;
    height: 24px;
    border: 1px solid #C6C6C6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #797979;
    transition: .2s;

    &:hover {
      opacity: .7;
    }
  }
}

.form-switch {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 6px 0 0;

  label {
    color: #636363 !important;
  }

  input {
    border-color: #C8C8C8 !important;
    margin: 0 !important;
    float: unset !important;
    left: 0 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FF8E25'/%3e%3c/svg%3e") !important;

    &:checked {
      background-color: #FF8E25 !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
    }
  }
}

.report-collapse {
  .accordion-item {
    border: none;

    .accordion-button {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 8px;
      padding: 0;
      background: transparent;
      box-shadow: none;
      color: #000;

      &:after {
        margin: 0;
      }
    }

    .accordion-body {
      padding: 20px 0 0 0;
      color: #000;

      b {
        font-weight: 500;
      }

    }
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #807f7f;
    border-radius: 4px;
    border: 2px solid #f0f0f0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #5a5a5a;
  }
}

.form-radio-primary {
  .form-check-input:checked {
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 2px solid #FF8E25;
    --vz-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FF8E25'/%3e%3c/svg%3e");
  }
}

.flatpickr-calendar {
  .flatpickr-months {
    background: transparent;

    .flatpickr-prev-month {
      color: rgba(0, 0, 0, 1);
      font-size: 0;

      &:before {
        content: "";
        width: 6px;
        height: 14px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDcgMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjgyOTY4IDE0LjAwMDNDNS41MjcwOSAxNC4wMDEzIDUuMjQwMzEgMTMuODY1MyA1LjA0OTY4IDEzLjYzMDNMMC4yMTk2NzUgNy42MzAzQy0wLjA4MzY0MTggNy4yNjEzIC0wLjA4MzY0MTggNi43MjkzIDAuMjE5Njc1IDYuMzYwM0w1LjIxOTY4IDAuMzYwMjk4QzUuNTczMTQgLTAuMDY0OTYwOCA2LjIwNDQyIC0wLjEyMzE2NCA2LjYyOTY4IDAuMjMwMjk4QzcuMDU0OTMgMC41ODM3NjEgNy4xMTMxNCAxLjIxNTA0IDYuNzU5NjggMS42NDAzTDIuMjg5NjggNy4wMDAzTDYuNjA5NjggMTIuMzYwM0M2Ljg1OTM3IDEyLjY2IDYuOTExOTcgMTMuMDc3NiA2Ljc0NDQyIDEzLjQyOTlDNi41NzY4NyAxMy43ODIyIDYuMjE5NzUgMTQuMDA0OSA1LjgyOTY4IDE0LjAwMDNaIiBmaWxsPSIjNEQ0RDREIi8+Cjwvc3ZnPgo=");
        background-repeat: no-repeat;
        display: block;
      }
    }

    .flatpickr-current-month {
      .cur-month, .cur-year {
        color: rgba(0, 0, 0, 1);
      }
    }

    .flatpickr-next-month {
      color: rgba(0, 0, 0, 1);
      font-size: 0;

      &:before {
        content: "";
        width: 8px;
        height: 16px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDggMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjk5OTgxIDE0Ljk5OTlDMC43NjYxNTggMTUuMDAwNCAwLjUzOTcyMiAxNC45MTkgMC4zNTk4MSAxNC43Njk5QzAuMTU1MzAzIDE0LjYwMDQgMC4wMjY2NjIyIDE0LjM1NjQgMC4wMDIyNzMxMyAxNC4wOTE5Qy0wLjAyMjExNiAxMy44Mjc0IDAuMDU5NzUxMSAxMy41NjQgMC4yMjk4MSAxMy4zNTk5TDQuNzA5ODEgNy45OTk5NEwwLjM4OTgxIDIuNjI5OTRDMC4yMjIwNCAyLjQyMzM1IDAuMTQzNTQxIDIuMTU4NCAwLjE3MTY5NCAxLjg5Mzc2QzAuMTk5ODQ4IDEuNjI5MTIgMC4zMzIzMyAxLjM4NjYyIDAuNTM5ODEgMS4yMTk5NEMwLjc0ODk3NyAxLjAzNTkxIDEuMDI1NDQgMC45NDc0NyAxLjMwMjU4IDAuOTc1OTQzQzEuNTc5NzMgMS4wMDQ0MiAxLjgzMjQzIDEuMTQ3MjIgMS45OTk4MSAxLjM2OTk0TDYuODI5ODEgNy4zNjk5NEM3LjEzMzEzIDcuNzM4OTUgNy4xMzMxMyA4LjI3MDk0IDYuODI5ODEgOC42Mzk5NEwxLjgyOTgxIDE0LjYzOTlDMS42MjYzNSAxNC44ODU0IDEuMzE4MDUgMTUuMDE5MSAwLjk5OTgxIDE0Ljk5OTlaIiBmaWxsPSIjNEQ0RDREIi8+Cjwvc3ZnPgo=");
        background-repeat: no-repeat;
        display: block;
      }
    }
  }


  .flatpickr-innerContainer {

    .flatpickr-rContainer {
      .flatpickr-weekdays {
        background: transparent;

        .flatpickr-weekday {
          color: rgba(77, 77, 77, 1);
          background: transparent;
        }
      }

      .flatpickr-days {
        .flatpickr-day.today {
          background: transparent;
          border-color: rgba(77, 77, 77, 1);
        }

        .flatpickr-day.selected.startRange {
          position: relative;

          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 142, 37, 0.1);
            right: -6px;
          }
        }

        .flatpickr-day.selected.endRange {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            width: 50%;
            height: 100%;
            background-color: rgba(255, 142, 37, 0.1);
            left: 0;
          }
        }

        .flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange,
        .flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange{
          border-radius: 100px;
        }

        .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange {
          background: rgba(255, 142, 37, 1);
          border-color: rgba(255, 142, 37, 1);
        }

        .flatpickr-day.inRange {
          background: rgba(255, 142, 37, 0.1);
          box-shadow: -7px 0 0 rgba(255, 142, 37, 0.01), 6px 0 0 rgba(255, 142, 37, 0.1);
        }

        .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
          box-shadow: none;
        }
      }
    }
  }

}
